"use client";

import TextRevealByWord from "@/components/magicui/text-reveal";
import React from "react";

type Props = {};

function CreateEngagingExperiences({}: Props) {
  return (
    <div className="z-10 flex min-h-[16rem] items-center justify-center ">
      <TextRevealByWord
        className="text-black dark:text-black"
        text="Create engaging experiences by tapping into the human instinct to play. We help brands create an edge."
      />
    </div>
  );
}

export default CreateEngagingExperiences;
