"use client";

import { AnimatedList } from "@/components/magicui/animated-list";
import { cn } from "@/lib/utils";
import Image from "next/image";

interface Item {
  name: string;
  description: string;
  icon: string;
  color: string;
  time: string;
}

let notifications = [
  {
    name: "Payment received",
    description: "Magic UI",
    time: "15m ago",
    icon: "💸",
    color: "#00C9A7",
  },
  {
    name: "User signed up",
    description: "Magic UI",
    time: "10m ago",
    icon: "👤",
    color: "#FFB800",
  },
  {
    name: "New message",
    description: "Magic UI",
    time: "5m ago",
    icon: "💬",
    color: "#FF3D71",
  },
  {
    name: "New event",
    description: "Magic UI",
    time: "2m ago",
    icon: "🗞️",
    color: "#1E86FF",
  },
];

notifications = Array.from({ length: 100 }, () => notifications).flat();

const Notification = ({ name, description, icon, color, time }: Item) => {
  return (
    <figure
      className={cn(
        "relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-xl  p-2",
        "transition-all duration-200 ease-in-out ",
        "transform-gpu backdrop-blur-3xl"
      )}
    >
      <div className="flex justify-between text-xs leading-3 text-[0.7rem]">
        <div className="flex items-center gap-1">
          <Image src="/home/iosmail.png" width={20} height={20} alt="playe" />
          <p className="text-xs leading-3 text-[0.7rem] text-zinc-700">MAIL</p>
        </div>
        <p className="mr-1 text-xs leading-3 text-[0.7rem] text-zinc-700">Now</p>
      </div>

      <p className="mt-1 text-sm text-black">Playe</p>
      <p className="text-xs leading-3  text-[0.7rem] text-black/80">
        <span className="font-semibold text-black">Congratulations! You have been selected. </span>
        Follow the link to claim your e-voucher to spend on Apple{" "}
      </p>
    </figure>
  );
};

export function AnimatedNotifications({ className }: { className?: string }) {
  return (
    <div className={cn("relative flex h-full w-full flex-col p-6 overflow-hidden", className)}>
      <Image
        width={1200}
        height={1200}
        alt="number-of-entries"
        src="/home/iphone.png"
        title="Zapier"
        className="absolute left-1/2 -translate-x-1/2 object-contain  max-w-[350px] md:max-w-[400px] rounded -bottom-6"
      />

      <div className="-bottom-4 md:bottom-0 w-[200px] md:w-[240px] left-1/2 -translate-x-1/2 h-[160px] absolute">
        {/* <Notification {...notifications[1]} /> */}
        <AnimatedList>
          {notifications.map((item, idx) => (
            <Notification {...item} key={idx} />
          ))}
        </AnimatedList>
      </div>
    </div>
  );
}
