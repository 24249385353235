"use client";

import Image from "next/image";
import React from "react";
import { motion, useInView } from "framer-motion";

const Header = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      className="bg-[#2d2d2d] flex items-center justify-center  md:max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto md:rounded-3xl"
    >
      <motion.div
        initial={{ scale: 1.5, opacity: 0 }}
        animate={isInView ? { scale: 1, opacity: 1 } : { scale: 1.5, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className=""
      >
        <Image
          alt="icon"
          src="/header/ipad-5.png"
          width={1200}
          height={1200}
          className="overflow-hidden scale-125 rounded md:scale-100"
        />
      </motion.div>
    </motion.div>
  );
};

export default Header;
