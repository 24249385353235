"use client";

import Globe from "@/components/magicui/globe";
import { TypographyH1 } from "@/components/Typography";
import { cn } from "@/lib/utils";
import Image from "next/image";
import React from "react";

type Props = {};

const InspirationToCreation = (props: Props) => {
  return (
    <div className="px-4 py-4 mx-auto md:py-20 max-w-7xl">
      <TypographyH1 className="pb-20 text-2xl text-center text-black">From inspiration to creation.</TypographyH1>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        <div className={cn("flex flex-col rounded-lg")}>
          <div className="relative w-full rounded-lg bg-zinc-100 h-80 md:h-96">
            <div className="relative flex items-center justify-center w-full h-full px-40 pt-8 pb-40 overflow-hidden rounded-lg md:pb-60 ">
              <span className="text-6xl font-semibold leading-none text-center text-transparent whitespace-pre-wrap pointer-events-none bg-gradient-to-b from-black to-gray-300/80 bg-clip-text lg:text-8xl">
                Stores
              </span>
              <Globe className="top-28" />
              <div className="pointer-events-none absolute inset-0 h-full bg-[radial-gradient(circle_at_50%_200%,rgba(0,0,0,0.2),rgba(255,255,255,0))]" />
            </div>
          </div>
          <p className="mt-4 font-semibold text-center text-black">Multiple stores</p>
          <p className="text-sm text-center text-muted-foreground">
            Add new stores as you scale, and switch between them effortlessly.
          </p>
        </div>

        <div className={cn("flex flex-col rounded-lg")}>
          <div className="relative flex items-center justify-center w-full overflow-hidden rounded-lg bg-zinc-100 h-80 md:h-96">
            <Image
              src="/home/prizepool.svg"
              alt="globe"
              width={500}
              height={500}
              objectFit="cover"
              className="absolute w-auto rounded-lg -bottom-44 md:-bottom-28"
            />
          </div>
          <p className="mt-4 font-semibold text-center text-black ">Permission levels</p>
          <p className="text-sm text-center text-muted-foreground">
            Collect your favorite designs and upload your own screenshots into one place.
          </p>
        </div>
        <div className={cn("flex flex-col rounded-lg")}>
          <div className="relative flex items-center justify-center w-full overflow-hidden rounded-lg bg-zinc-100 h-80 md:h-96">
            <div className="w-[65%] rounded-xl  overflow-hidden">
              <Image
                src="/home/calendar-scroll.gif"
                alt="globe"
                width={500}
                height={500}
                objectFit="cover"
                className="w-auto scale-105 rounded-lg"
              />
            </div>
          </div>
          <p className="mt-4 font-semibold text-center text-black ">Schedule campaigns</p>
          <p className="text-sm text-center text-muted-foreground">
            Plan your future campagns in advance for optimal reach and engagement.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InspirationToCreation;
