"use client";

import { Button } from "@/components/ui/button";
import { Variants, motion } from "framer-motion";
import Image from "next/image";
import { EarningEstimationCalculatorButton } from "./earning-estimation-calculator-button";
import IconStackAnimation from "./icon-stack-animation";

type Props = {};

const logos = ["/icons/Dom.svg", "/icons/KFC.svg", "/icons/MD.svg", "/icons/MM.svg", "/icons/SB.svg", "/icons/SEP.svg"];

const HeroSection = (props: Props) => {
  const container: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  const child: Variants = {
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };

  return (
    <motion.section
      variants={container}
      initial="hidden"
      animate="visible"
      className="grid place-items-center px-4 pb-6 md:pb-20 pt-[108px]"
    >
      <motion.div variants={child} className="pt-16 pb-10">
        <IconStackAnimation />
        {/* <IconChangeAnimation /> */}
      </motion.div>
      <div className="grid gap-4 lg:gap-8 place-items-center">
        <motion.h1
          variants={child}
          className=" font-bold lg:text-[80px] md:text-[56px] text-[36px]  leading-none text-center text-black"
        >
          Play to win OS <br /> for modern brands
        </motion.h1>
        <motion.p variants={child} className="text-center text-zinc-500 text-[20px] leading-tight">
          Find your moat, the only platform that makes customers coming
          <br />
          back for more. Host a game today.
        </motion.p>
        <motion.div variants={child} className="pt-6 md:pt-0">
          <div className="flex gap-2 md:gap-4">
            <Button size="lg" variant="secondary" className="px-4 rounded-full text-[16px]">
              See our plans
            </Button>
            <EarningEstimationCalculatorButton />
          </div>
        </motion.div>
      </div>

      <motion.p variants={child} className="pt-20 text-xs text-muted-foreground">
        Democratising legacy technologies used by giants at
      </motion.p>

      <motion.div variants={child} className="grid grid-cols-3 grid-rows-1 gap-10 pt-6 md:grid-cols-5 lg:grid-cols-6">
        <Image
          width={200}
          height={200}
          src={logos[0]}
          alt="icon"
          className="h-[80px] w-auto object-cover text-3xl font-semibold"
        />
        <Image
          width={200}
          height={200}
          src={logos[1]}
          alt="icon"
          className="h-[80px] w-auto object-cover text-3xl font-semibold"
        />
        <Image
          width={200}
          height={200}
          src={logos[2]}
          alt="icon"
          className="h-[80px] w-auto object-cover text-3xl font-semibold"
        />
        <Image
          width={200}
          height={200}
          src={logos[3]}
          alt="icon"
          className="h-[80px] w-auto object-cover hidden text-3xl font-semibold md:block"
        />
        <Image
          width={200}
          height={200}
          src={logos[4]}
          alt="icon"
          className="h-[80px] w-auto object-cover hidden text-3xl font-semibold md:block"
        />
        <Image
          width={200}
          height={200}
          src={logos[5]}
          alt="icon"
          className="h-[80px] w-auto object-cover hidden text-3xl font-semibold lg:block"
        />
      </motion.div>
    </motion.section>
  );
};

export default HeroSection;
