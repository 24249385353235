"use client";

import React, { useEffect, useRef } from "react";
import Matter from "matter-js";

const logos = [
  {
    icon: "/icons/american-apparel-2.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/AMZN.svg",
    renderSize: "big",
  },

  {
    icon: "/icons/APL.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/Asda-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/ASOS.com-Logo.wine.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/boohoo.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/boots-logo.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/Co-op_Food-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/dunelm-logo.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/EBAY.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/gap.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/h-m.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/JD_Sports-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/lacoste-logo.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/mango-4.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/Miss_Selfridge-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/MNS.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/Morrisons-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/Moss_Bros_Group-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/MS.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/Next_plc-Logo.wine.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/NKE.svg",
    renderSize: "big",
  },
  {
    icon: "/icons/primark.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/pull-bear-2.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/PYPL.svg",
    renderSize: "medium",
  },
  // {
  //   icon: "/icons/the-body-shop-3.svg",
  // },
  {
    icon: "/icons/tk-maxx-1.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/topshop-1.svg",
    renderSize: "medium",
  },
  {
    icon: "/icons/WHSmith-Logo.wine.svg",
    renderSize: "small",
  },
  {
    icon: "/icons/zara-logo-1.svg",
    renderSize: "big",
  },
];

const TechMatter = () => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    const renderTech = () => {
      const containerElement = containerRef.current;
      if (!containerElement) return;

      const canvasWidth = containerElement.clientWidth;
      const canvasHeight = containerElement.clientHeight;
      const isMobile = window.innerWidth <= 767;
      const isTablet = window.innerWidth < 992 && window.innerWidth > 767;
      const initialY = isMobile ? -200 : -550;

      const engine = Matter.Engine.create();
      const render = Matter.Render.create({
        element: containerElement,
        engine: engine,
        options: {
          width: canvasWidth,
          height: canvasHeight,
          background: "transparent",
          wireframes: false,
          showAngleIndicator: false,
        },
      });

      //if mobile remove 8 logos
      if (isMobile) {
        logos.splice(0, 8);
      }

      logos.forEach((logo) => {
        let radius, scale;

        if (logo.renderSize === "big") {
          radius = 70;
          scale = 1.5;
          if (isTablet) {
            radius = 62;
            scale = 1.2;
          }
          if (isMobile) {
            radius = 36;
            scale = 0.7;
          }
        } else if (logo.renderSize === "medium") {
          radius = 55;
          scale = 1.2;
          if (isTablet) {
            radius = 52;
            scale = 1;
          }
          if (isMobile) {
            radius = 29;
            scale = 0.57;
          }
        } else if (logo.renderSize === "small") {
          radius = 45;
          scale = 1;
          if (isTablet) {
            radius = 47;
            scale = 0.9;
          }
          if (isMobile) {
            radius = 26;
            scale = 0.5;
          }
        }

        const body = Matter.Bodies.circle(canvasWidth / 2, initialY, radius || 100, {
          friction: 0.3,
          restitution: 0.2,
          render: {
            sprite: {
              texture: logo.icon,
              yScale: scale || 2,
              xScale: scale || 2,
            },
          },
        });
        Matter.Composite.add(engine.world, body);
      });

      // Add boundaries
      const ground = Matter.Bodies.rectangle(canvasWidth / 2, canvasHeight + 30, 1500, 60, {
        isStatic: true,
        render: {
          opacity: 0,
        },
      });
      const leftWall = Matter.Bodies.rectangle(-30, canvasHeight / 2, 60, 5 * canvasHeight, {
        isStatic: true,
        render: {
          opacity: 0,
        },
      });
      const rightWall = Matter.Bodies.rectangle(canvasWidth + 30, canvasHeight / 2, 60, 5 * canvasHeight, {
        isStatic: true,
        render: {
          opacity: 0,
        },
      });
      const ceiling = Matter.Bodies.rectangle(canvasWidth / 2, 0, 1500, 60, {
        isStatic: true,
        render: {
          opacity: 0,
        },
      });

      Matter.Composite.add(engine.world, [ground, leftWall, rightWall]);

      // Add mouse interaction
      const mouse = Matter.Mouse.create(render.canvas);
      const mouseConstraint = Matter.MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });
      Matter.Composite.add(engine.world, mouseConstraint);

      // Remove default mouse scroll behavior
      //@ts-ignore
      mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
      //@ts-ignore
      mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);

      // Start the engine and render loop
      Matter.Render.run(render);
      const runner = Matter.Runner.create();
      Matter.Runner.run(runner, engine);

      // Add ceiling after a delay for visual effect
      setTimeout(() => {
        Matter.Composite.add(engine.world, [ceiling]);
      }, 2000);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          renderTech();
          observer.unobserve(entry.target);
          console.log("done");
        }
      });
    }, {});

    const containerElement = containerRef.current;
    if (containerElement) {
      observer.observe(containerElement);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="relative h-full overflow-hidden">
      {/* <h2 className="pt-6 pl-6 text-lg md:text-xl">
        Redeemable credit for your brand
        <br />
        champions across hundreds of stores
      </h2> */}
      {/* <img
          src="https://assets-global.website-files.com/65cfa850aaff127644e69130/65cfa850aaff127644e69910_Ellipse%20405.svg"
          loading="lazy"
          alt=""
          className="w-full -top-[10%] absolute -z-1"
        ></img> */}
      <div ref={containerRef} className="relative w-full h-full "></div>
    </div>
  );
};

export default TechMatter;
