"use client";

import { motion, useScroll, useTransform } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef } from "react";
import { TypographyH2, TypographyP } from "../Typography";

export const PriceTireV2 = () => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  const scale = useTransform(scrollYProgress, [0, 0.2, 1], [1, 1, 0.7]);
  const y = useTransform(scrollYProgress, [0.8, 1], ["0%", "-100%"]);
  const filter = useTransform(scrollYProgress, [0.4, 0.6, 0.6], ["blur(0px)", "blur(10px)", "opacity(0)"]);

  // New background color animation
  const backgroundColor = useTransform(
    scrollYProgress,
    [-1, -0.98, 0, 0.98, 1],
    ["#fff", "rgb(0, 0, 0)", "rgb(0, 0, 0)", "rgb(0, 0, 0)", "#fff"]
  );

  useEffect(() => {
    console.log(scrollYProgress.get());
  }, [scrollYProgress]);

  return (
    <motion.section
      ref={targetRef}
      style={{ backgroundColor }}
      className="flex w-full py-[80px] mx-auto h-[300vh] md:h-[400vh] relative flex-col items-center justify-start transform-gpu"
    >
      <div className="sticky top-[16.7vh] h-[66.8vh] text-2xl leading-[1] text-white transform-gpu">
        <motion.div className="relative z-40 h-full">
          <motion.figure style={{ y, scale }} className="flex justify-center h-full mx-4">
            <img src="/price-89.svg" className="object-contain w-auto h-full" />
          </motion.figure>
        </motion.div>

        <motion.div
          style={{
            filter: filter,
          }}
          className="absolute top-0 w-full flex flex-col items-center left-1/2 translate-x-[-50%] transform-gpu"
        >
          <TypographyH2 className="absolute w-[100vw] text-center top-0 text-white -translate-x-1/2 left-1/2 z-[99] font-bold">
            Starting from
          </TypographyH2>
          <div className="relative h-[500px] md:h-[600px] flex items-center ">
            <Image
              width={800}
              height={1200}
              quality={100}
              className="object-contain w-full h-auto "
              src="/hero-bg-tablet_1x.122e0053.jpg"
              alt="tire"
            />

            <Image
              width={800}
              height={1200}
              quality={100}
              className="absolute object-contain w-full h-auto price-image price-dots"
              src="/hero-dots-tablet_1x.89d631d4.png"
              alt="tire"
            />
          </div>
          <TypographyP className="text-white absolute bottom-0 flex gap-1 z-[99] font-bold">
            $89/mo per store
          </TypographyP>
        </motion.div>
      </div>
    </motion.section>
  );
};
