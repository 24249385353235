"use client";

import { TypographyH1 } from "@/components/Typography";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { IntelligentAreaChart, IntelligentBarChart, IntelligentLineChart } from "./intelligent-insights-charts";

const IntelligentInsights = () => {
  return (
    <div className="px-4 py-4 mx-auto md:py-20 max-w-7xl">
      <TypographyH1 className="pb-20 text-2xl text-center text-black">
        Intelligent Insights fuelled <br /> by your customers.
      </TypographyH1>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-3 ">
        <div className={cn("flex flex-col rounded-lg col-span-1 overflow-hidden md:col-span-2")}>
          <div className="relative grid w-full grid-cols-2 gap-6 bg-white rounded-lg h-80 md:h-96 ">
            <div className="relative w-full rounded-lg bg-zinc-100 h-80 md:h-96 "></div>
            <div className="relative w-full rounded-lg bg-zinc-100 h-80 md:h-96 "></div>

            <div className="absolute z-40 w-6 h-full -translate-x-1/2 bg-white left-1/2"></div>

            <motion.div
              className="absolute w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2"
              animate={{
                left: ["40%", " 60%", "40%"],
              }}
              transition={{
                duration: 4,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
              }}
            >
              <div className="flex items-center justify-center w-full h-full gap-6">
                <div className="h-[60%] w-[50%] md:w-[30%]">
                  <IntelligentLineChart />
                </div>
                <div className="h-[60%] w-[50%] md:w-[30%]">
                  <IntelligentAreaChart />
                </div>
              </div>
            </motion.div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="mt-4 font-semibold text-center text-black">ATV increase</p>
              <p className="text-sm text-center text-muted-foreground">
                Host campaigns with custom minimum purchase value
              </p>
            </div>
            <div>
              <p className="mt-4 font-semibold text-center text-black">Engagement</p>
              <p className="text-sm text-center text-muted-foreground">
                Measures gameplay duration for every customer immersing with your campaign.
              </p>
            </div>
          </div>
        </div>

        <div className={cn("flex flex-col rounded-lg")}>
          <div className="relative flex items-center justify-center w-full overflow-hidden rounded-lg bg-zinc-100 h-80 md:h-96 ">
            <IntelligentBarChart />
          </div>
          <p className="mt-4 font-semibold text-center text-black ">Loyalty</p>
          <p className="text-sm text-center text-muted-foreground">
            Identify and understand your customers through segmented data from your launchpad.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntelligentInsights;
