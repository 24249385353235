"use client";

import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import BurstingLogos from "./bursting-logos";

const AttractAndRetain = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  return (
    <div ref={containerRef} className={`relative z-0 h-[300vh]`}>
      <div className="sticky top-0 flex flex-col items-center justify-center h-screen">
        <div className="font-bold text-black md:text-2xl">Games that</div>
        <BurstingLogos />
        {["Attract", "Retain and", "Engage", "Engage"].map((line, index) => (
          <Line key={index} text={line} progress={scrollYProgress} index={index} />
        ))}
      </div>
    </div>
  );
};
export default AttractAndRetain;

interface LineProps {
  text: string;
  progress: any;
  index: number;
}

const Line = ({ text, progress, index }: LineProps) => {
  const y = useTransform(progress, [index / 3, (index + 1) / 3], [300, 0]);
  const opacity = useTransform(progress, [index / 3, (index + 1) / 3], [0, 1]);

  return (
    <motion.div style={{ y, opacity }} className="z-50 mb-1 text-4xl font-bold text-black md:mb-0 md:text-7xl">
      {text}
    </motion.div>
  );
};
