"use client";

import React, { useState } from "react";
import { motion } from "framer-motion";

const GrowthCard = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex items-center justify-center w-full h-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative h-[50%]  w-[70%] mx-auto cursor-pointer overflow-hidden rounded-xl border border-zinc-100 bg-white ">
        <p className="absolute left-5 top-5 w-fit text-[15px] text-zinc-500">+245%</p>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          preserveAspectRatio="none"
          className="absolute inset-0 w-full h-full"
          initial={{
            viewBox: "0 -950 366 1408",
            filter: "saturate(0.3)",
            opacity: 0.5,
          }}
          animate={{
            viewBox: isHovered ? "0 -60 366 310" : "0 -950 366 1408",
            filter: isHovered ? "saturate(1)" : "saturate(0.3)",
            opacity: isHovered ? 1 : 0.5,
          }}
          transition={{ duration: 0.3 }}
        >
          <path fill="url(#a)" d="M0 193c109.5 0 260.5-52.5 366-192.5v907H0V193Z" />
          <defs>
            <linearGradient id="a" x1="183" x2="183" y1="0.5" y2="262" gradientUnits="userSpaceOnUse">
              <stop stopColor="#22c55e" />
              <stop offset="1" stopColor="#15803d" />
            </linearGradient>
          </defs>
        </motion.svg>
      </div>
    </div>
  );
};

export default GrowthCard;
