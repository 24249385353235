interface IIndustryData {
    industry: string;
    grossProfitMargin: number;
    netProfitMargin: number;
}


export const industriesData: IIndustryData[] = [
    {
        industry: "Advertising",
        grossProfitMargin: 26.20,
        netProfitMargin: 3.10
    },
    {
        industry: "Apparel",
        grossProfitMargin: 53.04,
        netProfitMargin: 7.06
    },
    {
        industry: "Auto",
        grossProfitMargin: 14.25,
        netProfitMargin: 3.96
    },
    {
        industry: "Alcoholic Beverages",
        grossProfitMargin: 47.99,
        netProfitMargin: 5.07
    },
    {
        industry: "Broadcasting",
        grossProfitMargin: 45.22,
        netProfitMargin: 10.40
    },
    {
        industry: "Business & Consumer Services",
        grossProfitMargin: 31.80,
        netProfitMargin: 4.97
    },
    {
        industry: "Computer Services",
        grossProfitMargin: 27.24,
        netProfitMargin: 3.42
    },
    {
        industry: "Computers/Peripherals",
        grossProfitMargin: 36.88,
        netProfitMargin: 18.72
    },
    {
        industry: "Drugs (Biotechnology)",
        grossProfitMargin: 62.25,
        netProfitMargin: -0.62
    },
    {
        industry: "Drugs (Pharmaceutical)",
        grossProfitMargin: 67.35,
        netProfitMargin: 11.03
    },
    {
        industry: "Education",
        grossProfitMargin: 47.90,
        netProfitMargin: 7.17
    },
    {
        industry: "Electrical Equipment",
        grossProfitMargin: 33.53,
        netProfitMargin: 7.26
    },
    {
        industry: "Electronics (Consumer & Office)",
        grossProfitMargin: 32.41,
        netProfitMargin: 7.08
    },
    {
        industry: "Electronics (General)",
        grossProfitMargin: 28.40,
        netProfitMargin: 7.02
    },
    {
        industry: "Engineering/Construction",
        grossProfitMargin: 13.45,
        netProfitMargin: 1.81
    },
    {
        industry: "Entertainment",
        grossProfitMargin: 41.94,
        netProfitMargin: 3.86
    },
    {
        industry: "Farming/Agriculture",
        grossProfitMargin: 13.61,
        netProfitMargin: 6.03
    },
    {
        industry: "Financial Svcs. (Non-bank & Insurance)",
        grossProfitMargin: 85.08,
        netProfitMargin: 32.33
    },
    {
        industry: "Food Processing",
        grossProfitMargin: 27.00,
        netProfitMargin: 8.44
    },
    {
        industry: "Food Wholesalers",
        grossProfitMargin: 14.85,
        netProfitMargin: 0.69
    },
    {
        industry: "Furniture",
        grossProfitMargin: 29.74,
        netProfitMargin: 7.64
    },
    {
        industry: "Green & Renewable Energy",
        grossProfitMargin: 62.92,
        netProfitMargin: -19.78
    },
    {
        industry: "Healthcare Products",
        grossProfitMargin: 59.04,
        netProfitMargin: 12.92
    },
    {
        industry: "Heathcare Information and Technology",
        grossProfitMargin: 52.49,
        netProfitMargin: 16.64
    },
    {
        industry: "Hotel/Gaming",
        grossProfitMargin: 55.45,
        netProfitMargin: -28.56
    },
    {
        industry: "Household Products",
        grossProfitMargin: 50.13,
        netProfitMargin: 12.45
    },
    {
        industry: "Machinery",
        grossProfitMargin: 35.42,
        netProfitMargin: 10.79
    },
    {
        industry: "Office Equipment & Services",
        grossProfitMargin: 33.40,
        netProfitMargin: 2.55
    },
    {
        industry: "Precious Metals",
        grossProfitMargin: 52.43,
        netProfitMargin: 14.48
    },
    {
        industry: "Publishing & Newspapers",
        grossProfitMargin: 42.65,
        netProfitMargin: 3.55
    },
    {
        industry: "Restaurant/Dining",
        grossProfitMargin: 31.52,
        netProfitMargin: 12.63
    },
    {
        industry: "Software (Entertainment)",
        grossProfitMargin: 64.45,
        netProfitMargin: 29.04
    },
    {
        industry: "Software (Internet)",
        grossProfitMargin: 61.00,
        netProfitMargin: -10.36
    },
    {
        industry: "Software (System & Application)",
        grossProfitMargin: 71.59,
        netProfitMargin: 19.66
    },
    {
        industry: "Tobacco",
        grossProfitMargin: 62.87,
        netProfitMargin: 20.58
    }
];