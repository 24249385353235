import CurrencySelect from "@/components/CurrencySelect";
import { industriesData } from "@/utils/calculate-data";
import { Autocomplete, MantineProvider, NumberInput, Slider, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";

type Props = {};

interface CalculateFormValues {
  industry: string;
  grossProfitMargin: number;
  winRate: number;
  gamepasses: number;
  minimumPurchase: number;
  prizePool: number;
  currency: string;
  perHostedGamePrice: number;
  roi: number;
}

const EarningEstimationCalculator = ({}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const { setValue, watch, control } = useForm<CalculateFormValues>({
    defaultValues: {
      gamepasses: 0,
      grossProfitMargin: 0,
      industry: "",
      minimumPurchase: 0,
      // prizePool: 0,
      winRate: 0,
      currency: "GBP",
      roi: 0,
    },
  });
  const values = watch();

  useEffect(() => {
    if (values.gamepasses && values.minimumPurchase) {
      const roi = values.gamepasses * values.minimumPurchase;
      if (values.roi !== roi) setValue("roi", roi);
    }
  }, [values.gamepasses, values.minimumPurchase]);

  return (
    <div className="light">
      <MantineProvider defaultColorScheme="light" forceColorScheme="light">
        <div ref={ref} className="relative flex flex-col justify-between max-h-screen px-4 font-sans sm:max-w-md">
          {/* <TypographyH5 className="font-semibold text-black">Estimate Earning</TypographyH5> */}

          <div className="space-y-2">
            <div className="inline-block px-2 py-0.5 text-xs  bg-blue-500">Step 1</div>
            <div className="flex items-center gap-2 text-sm">
              <h1 className="text-black">What industry does your business operate in?</h1>
              <Tooltip
                zIndex={4001}
                multiline
                w={220}
                withArrow
                label="Data source from Polymersearch, a leading business intelligent platform."
              >
                <IconInfoCircle className="w-4 text-black" />
              </Tooltip>
            </div>

            <Controller
              control={control}
              name="industry"
              render={({ field }) => (
                <Autocomplete
                  placeholder="e.g restaurants/takeaway"
                  data={industriesData.map((item) => item.industry)}
                  styles={{
                    dropdown: {
                      zIndex: 5000,
                      color: "black",
                    },
                  }}
                  {...field}
                />
              )}
            />
          </div>

          <div className="mt-4 space-y-2">
            <div className="inline-block px-2 py-0.5 text-xs  bg-blue-500">Step 2</div>
            <h1 className="text-sm text-black">Value of prize pool</h1>

            <div className="grid grid-cols-2 gap-4">
              <Controller
                control={control}
                name="prizePool"
                defaultValue={0}
                render={({ field }) => <NumberInput min={0} placeholder="Enter value" hideControls {...field} />}
              />

              <CurrencySelect value={values.currency} onChange={(val) => setValue("currency", val)} />
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <div className="flex items-center gap-2">
              <h1 className="text-sm font-bold text-black">Gamepass</h1>
              <Tooltip
                zIndex={4001}
                multiline
                w={180}
                withArrow
                label="Level the playing field by limiting the number of participants a game is open to."
              >
                <IconInfoCircle className="w-4 text-black" />
              </Tooltip>
            </div>
            <h1>
              <span className="font-bold">{values.winRate === 0 ? 0 : values.gamepasses}</span>
            </h1>
          </div>
          <Controller
            control={control}
            name="gamepasses"
            render={({ field }) => (
              <Slider
                thumbSize="1rem"
                disabled={!values.prizePool}
                thumbChildren={
                  <div className="">
                    <div className="w-4 h-4 bg-blue-500 rounded-full pulse"></div>
                  </div>
                }
                {...field}
                onChange={(value) => {
                  setValue("winRate", parseFloat(((1 / Number(value)) * 100).toFixed(2)));

                  var minPurchaseValue = (Number(values.prizePool) * 4) / value;
                  setValue("minimumPurchase", Math.max(Number(minPurchaseValue.toFixed(2)), 5));

                  field.onChange(value);
                }}
              />
            )}
          />
          <div className="flex justify-between text-xs text-zinc-400">
            <h1>100%</h1>
          </div>

          <div className="p-2 mt-4 border rounded-sm bg-blue-50">
            <div className="text-sm font-bold text-blue-400">Disclaimer</div>
            <div className="text-xs font-bold text-zinc-950">
              Ensure your campaigns attract players by keeping a win rate between our recommended 5-10% per host.
              However, this may be superseded where the prize pool value is substantially high.
            </div>
          </div>
        </div>

        <div className="">
          <div className="p-4">
            <h1 className="flex items-end justify-between">
              <span className="inline-block px-2 py-1 mt-6 text-sm bg-teal-500 ">Recommended</span>
              <span
                className="text-xs text-blue-500 cursor-pointer"
                onClick={() => {
                  setValue("gamepasses", values.winRate === 0 ? 0 : Math.round(100 / values.winRate!));
                  setValue(
                    "minimumPurchase",
                    Math.round(
                      ((Number(values.prizePool) * 2) / values.winRate!) * (1 - values.grossProfitMargin! / 100)
                    )
                  );
                }}
              >
                Reset
              </span>
            </h1>

            <div className="pt-3 space-y-1">
              <h1 className="text-sm font-semibold text-black">Player win rate</h1>
              <div className="flex justify-between">
                <h1 className="text-xs text-zinc-700">Defined by the win over total entrants</h1>
                <div className="flex gap-1">
                  <span className="">{values.winRate}%</span>
                  <a href="#" className="text-xs leading-5 text-blue-500 underline">
                    Edit
                  </a>
                </div>
              </div>
              <Controller
                control={control}
                name="winRate"
                render={({ field }) => (
                  <Slider
                    className="pointer-events-none"
                    thumbSize="1rem"
                    thumbChildren={
                      <div className="">
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                      </div>
                    }
                    max={50}
                    {...field}
                  />
                )}
              />

              <div className="flex justify-between text-xs text-zinc-400">
                <h1>0%</h1>
                <h1>100%</h1>
              </div>
            </div>

            <div className="mt-6 space-y-1 "></div>
            <div className="mt-2 space-y-1 ">
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <h1 className="text-sm font-bold text-black">Minimum Purchase Value</h1>
                  <Tooltip
                    zIndex={4001}
                    multiline
                    w={180}
                    withArrow
                    label="Players recommends a minimum spend per customer in order to qualify for a gamepass."
                  >
                    <IconInfoCircle className="w-4 text-black" />
                  </Tooltip>
                </div>
                <h1>
                  <span className="font-bold">{values.minimumPurchase}</span>
                </h1>
              </div>
              <Controller
                control={control}
                name="minimumPurchase"
                render={({ field }) => (
                  <Slider
                    styles={{ thumb: { borderWidth: 0, padding: 0 } }}
                    thumbSize="1rem"
                    thumbChildren={
                      <div className="">
                        <div className="w-4 h-4 bg-blue-500 rounded-full pulse"></div>
                      </div>
                    }
                    {...field}
                    onChange={(value) => {
                      if (Number(value) < 5) {
                        setValue("minimumPurchase", 5);
                        return;
                      }
                      setValue("minimumPurchase", Number(value));
                    }}
                  />
                )}
              />
              <div className="flex justify-between text-xs text-zinc-400">
                <h1>100%</h1>
              </div>
            </div>
          </div>

          <div className="p-4 pt-4 text-black border-t">
            <p className="flex items-end justify-end gap-2">
              <span className="text-sm">Estimated Earnings</span>
              <span className="text-2xl font-bold">${values.roi.toFixed(2)}</span>
            </p>
          </div>

          {/* <div className="text-black">
            <div className="w-full p-4 mt-4 ">
              <div className="flex ">
                <div className="text-xl font-medium ">
                  {currencySelectData.find((d) => d.value === values.currency)?.symbol || "£"}
                </div>
                <div className="text-4xl font-medium ">{values.roi}</div>
                <div className="items-end self-end justify-end text-sm font-medium ">/ per hosted game</div>
              </div>
              <div className="mt-2 text-xs font-medium">
                Based on the defined criteria and recommendations made by Playe.
              </div>
            </div>
          </div> */}
        </div>
      </MantineProvider>
    </div>
  );
};

export default EarningEstimationCalculator;
