"use client";

import { Button } from "@/components/ui/button";
import { Drawer } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ArrowRight } from "lucide-react";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import EarningEstimationCalculator from "./earning-estimation-calculator";

interface EarningCalculatorProps {
  minPurchaseValue?: number[];
  winRate?: number;
  selection?: string;
  prize?: string;
  gamepasses?: number[];
  campaignType?: string;
  payout?: number;
  roi?: number;
}

export function EarningEstimationCalculatorButton() {
  const form = useForm<EarningCalculatorProps>({
    defaultValues: {
      campaignType: "SingleWinner",
      // gamepasses: [10],
      // minPurchaseValue: [20],
      // payout: 0,
      prize: "InStoreRedemption",
      roi: 0,
      selection: "HighScore",
      winRate: 1,
    },
  });

  const onSubmit = (data: EarningCalculatorProps) => {
    console.log(data);
  };

  const watch = useWatch({
    control: form.control,
  });

  useEffect(() => {
    const formValue = form.watch();
    if (formValue.gamepasses && formValue.minPurchaseValue) {
      const roi = formValue.gamepasses[0] * formValue.minPurchaseValue[0];
      if (formValue.roi !== roi) form.setValue("roi", roi);
    }
  }, [watch.gamepasses, watch.minPurchaseValue]);

  const [opened, { open, close }] = useDisclosure(false);
  const matches = useMediaQuery("(max-width: 600px)");

  return (
    <div>
      <Button
        size="lg"
        variant="outline"
        onClick={open}
        className="text-black pr-2 pl-4 hover:bg-zinc-50 hover:border-zinc-200 hover:text-black bg-transparent text-[16px] rounded-full border-zinc-200"
      >
        Earnings
        <div className="p-1 ml-2 rounded-full bg-zinc-100">
          <ArrowRight size={16} color="black" />
        </div>
      </Button>

      <Drawer
        trapFocus={false}
        opened={opened}
        onClose={close}
        withCloseButton={true}
        position="right"
        title="Earning Estimation"
        styles={{
          header: {
            paddingLeft: "1rem",
          },
          title: {
            color: "black",
            fontWeight: "bold",
          },
        }}
        padding={0}
        size={matches ? "300px" : "md"}
      >
        <EarningEstimationCalculator />
      </Drawer>
    </div>
  );
}
