"use client";

import { motion } from "framer-motion";
import Image from "next/image";

const icons = [
  "/game/icons/6.svg",
  "/game/icons/7.svg",
  "/game/icons/8.svg",
  "/game/icons/9.svg",
  "/game/icons/10.svg",
  "/game/icons/11.svg",
  "/game/icons/12.svg",
  "/game/icons/13.svg",
];

const RotatingIconsCircle = () => {
  const radius = 200; // Adjust this value to change the size of the circle

  return (
    <div className="relative flex items-center justify-center h-full overflow-hidden">
      <motion.div
        className="absolute w-64 h-64 -top-44"
        animate={{ rotate: [0, 60, 0] }}
        transition={{ duration: 6, repeat: Infinity, ease: "linear" }}
      >
        {icons.map((Icon, index) => {
          const angle = (index / icons.length) * 2 * Math.PI;
          const x = radius * Math.cos(angle);
          const y = radius * Math.sin(angle);

          return (
            <motion.div
              key={index}
              className="absolute w-[80px] h-[80px]"
              style={{
                left: `calc(50% + ${x}px)`,
                top: `calc(50% + ${y}px)`,
                transform: "translate(50%, 50%)",
              }}
              animate={{ rotate: [0, -360, 0] }}
              transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
            >
              <Image className="overflow-hidden" width={160} height={160} src={Icon} alt={Icon} />
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default RotatingIconsCircle;
