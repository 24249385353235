import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useState } from "react";

const CARD_COLORS = [
  "/game/icons/1.jpg",
  "/game/icons/2.jpg",
  "/game/icons/3.jpg",
  "/game/icons/4.jpg",
  "/game/icons/5.jpg",
];
const CARD_OFFSET = 10;
const SCALE_FACTOR = 0.2;
const VISIBLE_CARDS = 3;

const IconStackAnimation = () => {
  const [cards, setCards] = useState(CARD_COLORS);

  const moveToEnd = () => {
    setCards((prevCards) => {
      const newCards = [...prevCards];
      const [firstCard] = newCards.splice(0, 1);
      newCards.push(firstCard);
      return newCards;
    });
  };

  useEffect(() => {
    const interval = setInterval(moveToEnd, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex items-center justify-center ">
      <ul className="relative w-[90px] h-[90px]">
        <AnimatePresence>
          {cards.slice(0, VISIBLE_CARDS).map((color, index) => (
            <motion.li
              key={color}
              className={`absolute  rounded-lg list-none ${color}`}
              style={{
                transformOrigin: "top center",
              }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: 1,
                y: 0,
                top: index * -CARD_OFFSET,
                scale: 1 - index * SCALE_FACTOR,
                zIndex: VISIBLE_CARDS - index,
              }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <Image alt="icon" src={color} width={100} height={100} className="overflow-hidden rounded-3xl" />
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </div>
  );
};

export default IconStackAnimation;
