"use client";

import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";

interface TypingAnimationProps {
  text: string;
  duration?: number;
  delay?: number;
  className?: string;
}

export default function TypingAnimation({ text, duration = 200, delay = 0, className }: TypingAnimationProps) {
  const [displayedText, setDisplayedText] = useState<string>("");
  const [i, setI] = useState<number>(0);
  const [startAnimation, setStartAnimation] = useState<boolean>(false);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setStartAnimation(true);
    }, delay);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [delay]);

  useEffect(() => {
    if (!startAnimation) return;

    const typingEffect = setInterval(() => {
      if (i < text.length) {
        setDisplayedText(text.substring(0, i + 1));
        setI(i + 1);
      } else {
        clearInterval(typingEffect);
      }
    }, duration);

    return () => {
      clearInterval(typingEffect);
    };
  }, [duration, i, startAnimation, text]);

  return (
    <h1
      className={cn(
        "font-display text-center text-4xl font-bold leading-[5rem] tracking-[-0.02em] drop-shadow-sm mr-2",
        className
      )}
    >
      {displayedText || (startAnimation ? "" : text)}
    </h1>
  );
}
