"use client";

import TypingAnimation from "@/components/magicui/typing-animation";
import { AnimatePresence, motion, useInView } from "framer-motion";
import { Check, Loader } from "lucide-react";
import Image from "next/image";
import React, { useEffect, useState } from "react";

const AnimatedChecklist = () => {
  const [showLoader, setShowLoader] = useState(true);
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false });

  useEffect(() => {
    if (isInView) {
      setShowLoader(true);
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, [isInView]);

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24,
      },
    },
  };

  return (
    <div ref={ref} className="relative flex items-center w-full overflow-hidden rounded-lg bg-zinc-100 h-80 md:h-96">
      <Image
        src="/home/mobile-with-receipt.svg"
        alt="globe"
        width={500}
        height={500}
        objectFit="cover"
        className="h-[90%] w-full   rounded-lg absolute"
      />

      <div className="z-30 flex flex-col items-start w-full h-full">
        <motion.li variants={itemVariants} className="ml-[60%] mt-20 flex items-center  py-2 bg-white  rounded-3xl">
          <CheckIconWithLoader isLoading={showLoader} />
          {!showLoader && (
            <div className="mr-0">
              <TypingAnimation
                duration={20}
                className="text-sm font-medium text-black md:text-base"
                text="Gradual Spacing"
              />
            </div>
          )}
        </motion.li>
        <motion.li variants={itemVariants} className="flex ml-[15%] mt-2 items-center  py-2 bg-white  rounded-3xl ">
          <CheckIconWithLoader isLoading={showLoader} />
          {!showLoader && (
            <div className="mr-0">
              <TypingAnimation
                duration={20}
                className="text-sm font-medium text-black md:text-base"
                text="Gradual Spacing"
              />
            </div>
          )}
        </motion.li>
        <motion.li variants={itemVariants} className="flex ml-[10%] mt-2 items-center  py-2 bg-white  rounded-3xl ">
          <CheckIconWithLoader isLoading={showLoader} />
          {!showLoader && (
            <div className="mr-0">
              <TypingAnimation
                duration={20}
                className="text-sm font-medium text-black md:text-base"
                text="Gradual Spacing"
              />
            </div>
          )}
        </motion.li>
        <motion.li variants={itemVariants} className="flex ml-[50%] mt-6 items-center  py-2 bg-white  rounded-3xl ">
          <CheckIconWithLoader isLoading={showLoader} />
          {!showLoader && (
            <div className="mr-0">
              <TypingAnimation
                duration={20}
                className="text-sm font-medium text-black md:text-base"
                text="Gradual Spacing"
              />
            </div>
          )}
        </motion.li>
      </div>
    </div>
  );
};

const CheckIconWithLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div className="relative px-2">
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            key="loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Loader className="w-5 h-5 text-blue-500 animate-spin" />
          </motion.div>
        ) : (
          <motion.div
            key="check"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Check className="w-5 h-5 text-white bg-green-500 rounded-full " />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AnimatedChecklist;
