import { CheckIcon, Combobox, Input, InputBase, useCombobox } from "@mantine/core";
import { useState } from "react";
import "../node_modules/flag-icons/css/flag-icons.min.css";

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

export const currencySelectData = [
  {
    value: "USD",
    label: "USD -US Dollar",
    icon: "fi fi-us",
    symbol: "$",
  },
  {
    value: "EUR",
    label: "EUR -Euro",
    icon: "fi fi-eu",
    symbol: "€",
  },
  {
    value: "GBP",
    label: "GBP -British Pound",
    icon: "fi fi-gb",
    symbol: "£",
  },
  {
    value: "CAD",
    label: "CAD -Canadian Dollar",
    icon: "fi fi-ca",
    symbol: "$",
  },
];

const CurrencySelect = ({ onChange, value: defaultValue }: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === "keyboard") {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex("active");
      }
    },
  });

  const [value, setValue] = useState<string | null>(defaultValue || "GBP");

  const options = currencySelectData.map((item) => (
    <Combobox.Option value={item.value} key={item.icon} active={item.value === value}>
      <div className="flex items-center justify-between gap-2 text-black">
        <div className="flex gap-2 text-sm">
          <span className={item.icon}></span>
          <span>{item.label}</span>
        </div>
        {item.value === value && <CheckIcon size={12} />}
      </div>
    </Combobox.Option>
  ));

  return (
    <Combobox
      zIndex={5000}
      store={combobox}
      resetSelectionOnOptionHover
      onOptionSubmit={(val) => {
        setValue(val);
        onChange && onChange(val);
        combobox.updateSelectedOptionIndex("active");
      }}
    >
      <Combobox.Target targetType="button">
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
        >
          {(
            <div className="">
              <span className={currencySelectData.find((item) => item.value === value)?.icon}></span>
              <span className="ml-2">{value}</span>
            </div>
          ) || <Input.Placeholder>Pick value</Input.Placeholder>}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default CurrencySelect;
