import React, { useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import Image from "next/image";

const logos = [
  { src: "/game/icons/6.svg", alt: "Notion logo", top: "0%", left: "53.19%", clockwise: true },
  { src: "/game/icons/7.svg", alt: "Headspace logo", top: "7.14%", left: "85.22%", clockwise: false },
  { src: "/game/icons/8.svg", alt: "Nike logo", top: "88.57%", left: "60.42%", clockwise: true },
  { src: "/game/icons/9.svg", alt: "Dropbox logo", top: "14.29%", left: "26.94%", clockwise: false },
  { src: "/game/icons/10.svg", alt: "Creme logo", top: "1.71%", left: "5.56%", clockwise: true },
  { src: "/game/icons/11.svg", alt: "Klarna logo", top: "20%", left: "68.33%", clockwise: false },
  { src: "/game/icons/12.svg", alt: "Yubo logo", top: "42.29%", left: "15.56%", clockwise: true },
  { src: "/game/icons/13.svg", alt: "Airbnb logo", top: "45.71%", left: "92.36%", clockwise: false },
  { src: "/game/icons/6.svg", alt: "Twitch logo", top: "81.43%", left: "19.39%", clockwise: true },
  { src: "/game/icons/7.svg", alt: "Wise logo", top: "70.71%", left: "40.83%", clockwise: false },
  { src: "/game/icons/9.svg", alt: "Threads logo", top: "69.43%", left: "79.1%", clockwise: true },
  { src: "/game/icons/8.svg", alt: "Threads logo", top: "69.43%", left: "0.1%", clockwise: false },
];

const BurstingLogos = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });
  const controls = useAnimation();

  React.useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const createCircularMotion = (top: string, left: string, isClockwise: boolean) => {
    const direction = isClockwise ? "clockwise" : "anti-clockwise";
    return {
      hidden: { opacity: 1, x: "0%", y: "0%" },
      visible: {
        opacity: 1,
        x: direction === "clockwise" ? [0, 10, 0, -10, 0] : [0, -10, 0, 10, 0],
        y: direction === "clockwise" ? [-10, 0, 10, 0, -10] : [10, 0, -10, 0, 10],
        transition: {
          x: { repeat: Infinity, duration: 4, ease: "linear" },
          y: { repeat: Infinity, duration: 4, ease: "linear" },
          opacity: { duration: 4 },
        },
      },
    };
  };

  return (
    <>
      <div
        ref={ref}
        className="absolute hidden top-[80px] sm:grid pointer-events-none place-items-center inset-y-64 inset-x-20"
      >
        <div className="relative w-[calc(100vw-200px)] h-[calc(100vh-100px)]">
          {logos.map((logo, index) => (
            <motion.div
              key={index}
              className="absolute w-16 h-16 md:w-[4.5rem] md:h-[4.5rem]"
              initial={{ x: "-50%", y: "-50%", left: "50%", top: "50%" }}
              animate={
                isInView
                  ? {
                      left: logo.left,
                      top: logo.top,
                      x: 0,
                      y: 0,
                    }
                  : {}
              }
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                duration: 2,
                delay: index * 0.06,
              }}
              style={{
                position: "absolute",
                top: logo.top,
                left: logo.left,
              }}
            >
              <motion.div
                initial="hidden"
                animate={controls}
                variants={createCircularMotion(logo.top, logo.left, logo.clockwise)}
                className="relative shrink-0 overflow-hidden after:absolute after:inset-0 after:rounded-[inherit] after:shadow-logo-inset min-720:size-80 min-720:rounded-24"
              >
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  width={460}
                  height={460}
                  className="object-cover w-16 h-16 rounded-2xl md:w-[4.5rem] md:h-[4.5rem]"
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
      <MobileLogos />
    </>
  );
};

export default BurstingLogos;

const icons = [
  { src: "/game/icons/6.svg", alt: "Notion logo", top: "0%", left: "56.52%", clockwise: true },
  { src: "/game/icons/7.svg", alt: "Headspace logo", top: "5.69%", left: "100.29%", clockwise: false },
  { src: "/game/icons/8.svg", alt: "Nike logo", top: "89.35%", left: "71.99%", clockwise: true },
  { src: "/game/icons/9.svg", alt: "Dropbox logo", top: "17.11%", left: "24.93%", clockwise: false },
  { src: "/game/icons/10.svg", alt: "Creme logo", top: "3.69%", left: "-8.74%", clockwise: true },
  { src: "/game/icons/11.svg", alt: "Klarna logo", top: "19.57%", left: "78.32%", clockwise: false },
  { src: "/game/icons/12.svg", alt: "Yubo logo", top: "41.06%", left: "-18.65%", clockwise: true },
  { src: "/game/icons/13.svg", alt: "Airbnb logo", top: "42.61%", left: "101.46%", clockwise: false },
  { src: "/game/icons/6.svg", alt: "Spotify logo", top: "73.38%", left: "-14.56%", clockwise: true },
  { src: "/game/icons/7.svg", alt: "Twitch logo", top: "85.07%", left: "14.27%", clockwise: false },
  { src: "/game/icons/8.svg", alt: "Wise logo", top: "74.14%", left: "44.91%", clockwise: true },
  { src: "/game/icons/9.svg", alt: "Threads logo", top: "73.38%", left: "99.4%", clockwise: false },
];

const MobileLogos = () => {
  return (
    <div className="absolute  grid sm:hidden pointer-events-none top-[80px] place-items-center inset-y-64 inset-x-20">
      <div className="relative aspect-[0.4] w-full">
        {icons.map((icon, index) => (
          <div key={index} className="absolute" style={{ top: icon.top, left: icon.left }}>
            <div className="relative shrink-0  after:absolute after:inset-0 after:rounded-[inherit] overflow-hidden after:shadow-logo-inset size-56 rounded-16">
              <Image
                src={icon.src}
                alt={icon.alt}
                width={460}
                height={460}
                className="object-cover size-14 rounded-2xl"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
