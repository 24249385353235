"use client";

import Counter from "@/components/Framer/Counter";
import { Divider, Slider } from "@mantine/core";
import { useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

type Props = {};

const SliderNumberUpAnimation = (props: Props) => {
  const [value, setValue] = useState(0);
  const initialValue = 0;
  const maxValue = 100;
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false, // Changed to false to allow multiple triggers
    threshold: 0.1,
  });

  const animateValue = (start: number, end: number, duration: number, onUpdate: (value: number) => void) => {
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      onUpdate(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5 },
      });

      animateValue(value, maxValue, 500, setValue);
    } else {
      controls.start({
        opacity: 0,
        y: 50,
        transition: { duration: 0.5 },
      });

      animateValue(value, initialValue, 500, setValue);
    }
  }, [inView, controls, value]);

  return (
    <div className="absolute w-full h-full -translate-x-1/2 top-[10%] left-1/2">
      <div className="flex items-center justify-center h-[80%]">
        <div
          ref={ref}
          className="md:w-1/2 w-[80%] flex-col flex h-full p-4 bg-[#F9FAFC] rounded-2xl shadow-transparent"
        >
          <p className="mb-4 text-sm text-gray-500">Players</p>
          <div className="flex flex-col justify-between h-[90%]">
            <p className="text-5xl font-medium text-black">
              {value.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })}
            </p>
            <div className="pointer-events-none">
              <Slider
                value={value}
                onChange={setValue}
                classNames={{
                  track: "before:bg-[#CCD2F5]",
                  bar: "bg-[#3D57DA]",
                  thumb: "bg-[#3D57DA] h-[40px] w-[25px] shadow-2xl shadow-slate-950 rounded-full",
                }}
                thumbChildren={
                  <div className="flex pointer-events-none border-[#3D57DA] border-4 rounded-full items-center justify-center w-full h-full bg-white"></div>
                }
                thumbSize={26}
                max={maxValue}
              />
              <Divider className="mt-10 mb-6" color="#DDDEE3" />
              <div className="flex justify-between text-gray-500">
                <p>Win rate</p>
                <p>
                  <Counter value={9.9} direction="up" /> %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderNumberUpAnimation;
